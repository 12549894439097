<template>
  <Layout>
    <div class="createLessons">
      <div class="container" data-anima="top">
        <ModalUpload
          :data="data"
          :media="media"
          :type="typeContentSelected"
          :typeLesson="typeContentSelected"
          @onMediaSelected="onMediaSelected"
        />
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5">
            <div class="navigate">
              <div class="title animationOpacity">
                {{ nameCourse }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson d-flex mt-2"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <IconSax class="ml-2" name="redo" type="outline" size="1rem" color="#c4c4c4" />
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <IconSax name="monitor" />
                  <p class="m-0 text ml-3">Dados do Curso</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto"  />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="book-square" />
                  <p class="m-0 text ml-3">Módulos e Aulas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="profile-2user" />
                  <p class="m-0 text ml-3">Turmas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="medal-star" />
                  <p class="m-0 text ml-3">Certificado</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
            <router-link
              :to="'/config_curso/emails/' + cursoAtual + ''"
              class="removeLink"
              :class="{ disableLink: existParans }"
            >
              <div class="spaceNav">
                <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
              </div>
            </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <IconSax name="bag-2" />
                  <p class="m-0 text ml-3">Vendas</p>
                  <IconSax name="arrow-right-3" size="1rem" class="ml-auto" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5">
            <div class="containerWizzard">
              <div class="title">{{ ModuleLessons.title }}</div>
            </div>
            <div class="containerWizzardInputs" v-if="!loading">
              <div class="spaceModuleCard" id="step12">
                <div class="actionModule">
                  <div class="titleModule">Gerenciamento de Aulas</div>
                </div>
                <div>
                  <draggable
                    :list="AllAulas"
                    :disabled="!enabled"
                    class="list-group"
                    ghost-class="ghost"
                    @end="onEnd"
                  >
                    <div
                      class="spaceInputs removemb viewAulas"
                      v-for="(lesson, index) in AllAulas"
                      :key="lesson.id"
                      :data-id="lesson.id"
                    >
                      <span v-if="editLesson === false && addAula === false">
                        <span
                          class="textSubcategory"
                          v-if="
                            AllAulas[index - 1] === undefined &&
                            lesson.small_category !== undefined
                          "
                          >{{ lesson.small_category }}</span
                        >
                        <span
                          class="textSubcategory"
                          v-else-if="
                            AllAulas[index - 1].small_category !==
                            lesson.small_category
                          "
                          >{{ lesson.small_category }}</span
                        >
                      </span>
                      <div
                        class="flexAula"
                        :class="{ marginSubCategory: lesson.small_category }"
                        v-if="editLesson === false && addAula === false"
                      >
                        <div class="flex">
                          <div
                            v-if="lesson.status === 'draft'"
                            class="textAula"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                            <span class="textAula2">(Rascunho)</span>
                          </div>
                          <div
                            v-else
                            class="d-flex align-items-center textAula"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                          </div>
                        </div>
                        <div class="flex">
                          <div class="timeAula">
                            {{ lesson.duration | moment("mm:ss") }}
                          </div>
                          <div class="actions actionAula d-flex align-items-center">
                            <IconSax name="edit" size="1rem" />
                            <b-dropdown
                              ref="myDropdown"
                              id="dropdown-dropright"
                              dropright
                              text=""
                              class="m-2 p-0"
                            >
                              <b-dropdown-item
                                @click="editLessonFuntion(lesson)"
                                >Editar Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="viewLesson(lesson.id)"
                                >Ver Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="deleteLesson(lesson.id)"
                                >Deletar Aula</b-dropdown-item
                              >
                            </b-dropdown>
                            <button
                              id="viewModuleLesson2"
                              class="viewModuleLesson d-flex"
                              @click="viewLesson(lesson.id)"
                            >
                              <p class="m-0">Ver Aula</p>
                              <IconSax name="redo" size="1rem" class="ml-2" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="spaceInputs removemb animationOpacity"
                    v-if="addAula === false || addAula === ''"
                  >
                    <b-form-group id="step13">
                      <div class="btn-openModal" @click="openCreateAula()">
                        <p class="text">Clique e adicione uma Aula</p>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="addAula === true" class="animationOpacity">
                  <div class="spaceInputs">
                    <b-form-group
                      :label="`Nome da Aula: ${nameAula.length}`"
                      label-for="name-aula"
                      id="step14"
                    >
                      <b-form-input
                        maxLength="255"
                        v-model="nameAula"
                        v-validate="'required|max:255|min:5'"
                        placeholder="Nome da Aula"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div
                    class="spaceInputs removemb"
                    v-if="mediaTypeLesson !== 'quiz' || !editLesson"
                  >
                    <b-form-group
                      label="Tipo de conteúdo"
                      label-for="name-aula"
                    >
                      <b-form-select
                        v-model="typeContentSelected"
                        :options="optionsTypeContent"
                        placeholder="Nome da Aula"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <Qiz
                    v-if="typeContentSelected === 'QUIZ'"
                    @addedQuestion="addedQuestion"
                    @removedQuestion="removedQuestion"
                    :questions="this.questions"
                    :inEdit="this.editLesson"
                    @closeModalOptions="closeModalOptions"
                  />
                  <div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO'
                      "
                    >
                      <!-- <div class="removeLessonVideo" @click="removeCourse()">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </div> -->
                      <b-form-group
                        label="Arquivo da Aula"
                        label-for="name-aula"
                        id="step15"
                      >
                        <div
                          class="btn-openModal"
                          @click="showModalUpload(ModuleLessons.id)"
                          v-if="midialessonpreview === null"
                        >
                          <p class="text">
                            Clique e adicione um
                            {{
                              typeContentSelected === "AUDIO"
                                ? "Audio"
                                : "Vídeo"
                            }}
                          </p>
                        </div>
                        <div class="btn-openModalPreview" v-else>
                          <iframe
                            class="imagePreviewYt animationOpacity"
                            height="100%"
                            v-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson !== 'panda'
                            "
                            width="50%"
                            :src="midialesson + '&controls=play-larg'"
                            style="border: 0; pointer-events: all"
                          ></iframe>
                          <div
                            v-else-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson === 'panda'
                            "
                          >
                            <img
                              src="@/assets/img/iframe.png"
                              class="imgAudio"
                              alt="audio"
                            />
                          </div>
                          <div v-else-if="mediaTypeLesson === 'audio'">
                            <img
                              src="@/assets/img/audio.png"
                              class="imgAudio"
                              alt="audio"
                            />
                            <p>{{ midialessontitle }}</p>
                          </div>
                          <img
                            v-else
                            class="imagePreviewYt animationOpacity"
                            @click="showModalUploadItem(ModuleLessons.id)"
                            :src="midialessonpreview"
                            :alt="midialessonpreview"
                          />
                          <div
                            class="removeLessonVideo"
                            @click="removeCourse()"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 6L6 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 6L18 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="lineDivAtt"></div>
                    <div class="spaceInputs2">
                      <b-form-group
                        label="Categoria (Opcional)"
                        label-for="name-aula"
                      >
                        <div>
                          <multiselect
                            noOptions="Sem Categoria, Escreva uma!"
                            tag-placeholder="Adicionar nova Categoria"
                            @tag="addTag"
                            placeholder="Selecione uma Categoria"
                            selectedLabel=""
                            deselectLabel="Pressione Enter"
                            :close-on-select="true"
                            selectLabel="Pressione Enter"
                            track-by="id"
                            :multiple="false"
                            :taggable="true"
                            v-model="categoryLesson"
                            :options="optionsLessonCategory"
                          ></multiselect>
                        </div>
                      </b-form-group>
                    </div>

                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO'
                      "
                    >
                      <b-form-group
                        label="Capa da aula"
                        label-for="name-aula"
                      >
                      <p class="textDimenssion">
                        Tamanho recomendado: 496 x 282px ou proporcional.<br>
                        Será exibida se o recurso "continue seu progresso" estiver ativo.
                      </p>

                        <div
                          class="btn-openModal"
                          @click="showModalUploadThumb(ModuleLessons.id)"
                          v-if="midialessonthumb === null"
                        >
                          <p class="text">Clique e adicione uma Thumb</p>
                        </div>
                        <div v-else class="btn-openModalPreview cursor-auto ">
                          <img
                            class="imagePreviewYt animationOpacity"
                            :src="midialessonthumb.url"
                            :alt="midialessonthumb.url"
                          />
                          <div
                            class="removeCustomThumb"
                            @click="removeCustomThumb()"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 6L6 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 6L18 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </b-form-group>
                    </div>

                    <div
                      class="spaceInputs"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                      <b-form-group
                        label="Descrição"
                        label-for="descricao"
                        id="step16"
                      >
                        <Editor
                          v-model="descricaoAula"
                          :onChange="onEditorChange"
                          placeholder="Digite aqui a descrição da aula..."
                        />
                      </b-form-group>
                    </div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                      <div>
                        <div class="lineDivAtt"></div>
                        <b-form-group
                          label="Arquivos Adicionais"
                          label-for="complement"
                        >
                          <div class="totalAtt">
                            Total de Arquivos: {{ quantidadeAtt }}
                          </div>
                          <div>
                            <div
                              class="btn-openModalPreview spaceAttList"
                              v-for="attach in AllAttachments"
                              :key="attach.id"
                            >
                            <p>{{ attach.title }}</p>
                              <!--<iframe
                                v-if="attach.mime === 'application/pdf'"
                                class="imagePreviewCapa animationOpacity"
                                height="100%"
                                width="100%"
                                :src="attach.cdn_url"
                              ></iframe>-->
                              <div v-if="attach.mime === 'audio/mpeg'">
                                <img
                                  src="@/assets/img/audio.png"
                                  class="imgAudio"
                                  alt="audio"
                                />
                              </div>

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="attach.mime === 'application/pdf'"
                                :src="`${assetsUrl}/images/file-types/PDF-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="attach.mime === 'application/zip'"
                                :src="`${assetsUrl}/images/file-types/ZIP-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/vnd.rar' ||
                                  attach.mime === 'application/x-rar' ||
                                  attach.mime === 'application/x-rar-compressed'
                                "
                                :src="`${assetsUrl}/images/file-types/RAR-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/msword' ||
                                  attach.mime === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                "
                                :src="`${assetsUrl}/images/file-types/DOC-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/vnd.ms-excel' ||
                                  attach.mime === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                  attach.mime === 'text/csv'
                                "
                                :src="`${assetsUrl}/images/file-types/XLS-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.cdn_url.includes('.pptx') ||
                                  attach.mime === 'application/vnd.ms-powerpoint' ||
                                  attach.mime === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                                "
                                :src="`${assetsUrl}/images/file-types/PPT-LM.svg`"
                                :alt="attach.title"
                              />

                              <img
                                v-else
                                class="imagePreviewCapa animationOpacity"
                                :src="attach.cdn_url"
                                :alt="attach.title"
                              />
                              <div
                                class="removeAttr"
                                @click="removeAttr(attach.id)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group label="" label-for="complement">
                          <div
                            class="btn-openModal"
                            @click="showModalUploadAditional()"
                            v-if="
                              midiaaditionalurl === '' ||
                              midiaaditionalurl === null
                            "
                          >
                            <p class="text">Faça o Upload dos Complementos</p>
                          </div>
                          <div
                            class="btn-openModalPreview"
                            @click="showModalUploadAditional()"
                            v-else
                          >
                            <iframe
                              v-if="midiaaditionalurltype === 'application/pdf'"
                              class="imagePreviewCapa animationOpacity"
                              height="100%"
                              width="100%"
                              :src="midiaaditionalurl"
                            ></iframe>
                            <img
                              v-else
                              class="imagePreviewCapa animationOpacity"
                              :src="midiaaditionalurl"
                              :alt="midiaaditionalurl"
                            />
                          </div>
                        </b-form-group>
                        <div
                          v-if="
                            midiaaditionalurl === '' ||
                            midiaaditionalurl === null
                          "
                        ></div>
                        <div class="lineDivAtt"></div>
                      </div>
                    </div>
                    <div class="spaceInputs" v-if="editLesson === false">
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="createAula(ModuleLessons.id, 'draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        id="step17"
                        @click.prevent="
                          createAula(ModuleLessons.id, 'published')
                        "
                      >
                        Publicar Aula
                      </button>
                    </div>
                    <div class="spaceInputs" v-else>
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="saveEditLesson('draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        @click.prevent="saveEditLesson('published')"
                      >
                        Publicar Aula
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/layout/Layout.vue";

import CourseService from "@/services/resources/CourseService";
import { ToggleButton } from "vue-js-toggle-button";
const serviceCourse = CourseService.build();
import notify from "@/services/libs/notificacao";
import draggable from "vuedraggable";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Qiz from "@/components/TypeContent/Quiz.vue";
import Editor from "@/components/Editor.vue";

export default {
  components: {
    Layout,
    ToggleButton,
    draggable,
    Multiselect,
    Qiz,
    Editor,
  },
  data() {
    return {
      nameCourse: "",
      nameModulo: "",
      url_domain: process.env.VUE_URL_DOMAIN,
      resumoCourse: "",
      descricaoCourse: "",
      categoryCourse: "",
      step: 0,
      AllCategory: 1,
      data: "introducao",
      midiaintroducao: "",
      midiacapa: "",
      midiaaulas: "",
      module_id_edit: "",
      ModuleLessons: "",
      totalModules: "",
      nameAula: "",
      descricaoAula: "",
      AllAulas: [],
      editNameModule: "",
      editModuleModal: false,
      editActualModule: "",
      cursoAtual: "",
      moduleAtual: "",
      addAula: false,
      myDataVariable: true,
      existParans: true,
      midialessonpreview: null,
      midialesson: "",
      editLesson: false,
      idEditLesson: "",
      mediaTypeLesson: "text",
      midialessontitle: "",
      midiaaditionalurl: "",
      midiaaditional: "",
      attatchments: false,
      nameLesson: "",
      quantidadeAtt: 0,
      AllAttachments: "",
      durationToEdit: "",
      enabled: true,
      dragging: false,
      midialessonthumb: null,
      midialessonthumbid: null,
      categoryLesson: null,
      optionsLessonCategory: [],
      midiaaditionalurltype: "",
      mediaPandaId: "",
      typeContentSelected: "VIDEO",
      optionsTypeContent: [
        { value: "TEXT", text: "Em Texto" },
        { value: "AUDIO", text: "Em Audio" },
        { value: "VIDEO", text: "Em Vídeo" },
        { value: "QUIZ", text: "Questionário" },
      ],
      quiz: {},
      questions: {},
      AllAudios: [],
      loading: false,
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    media() {
      return {
        type: this.mediaTypeLesson,
        data: this.midialesson
      }
    },
    assetsUrl() {
      return process.env.VUE_ASSETS_URL
    }
  },
  methods: {
    openDropdown() {
      this.$refs.myDropdown?.$mount();
    },
    async onMediaSelected(data) {
      if (data.origin == "uploadlesson") {
        this.mediaTypeLesson = data.type;
        this.midialesson = data.media;
        this.midialessonpreview = data.thumbnail;
      }
    },
    removeCourse() {
      var editLesson = this.editLesson;
      if (this.mediaTypeLesson == "audio") {
        this.AllAudios?.map?.((item) => {
          this.removeAudio(item.id);
        })
      }
      if (editLesson === true) {
        let data = {
          id: {
            id:
              this.cursoAtual +
              "/module/" +
              this.moduleAtual +
              "/lesson/" +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            midia_id: null,
            mediaType: "text",
            source: null,
            duration: null,
            thumb: null,
            status: "draft",
          },
        };
        this.loading = true;
        serviceCourse
          .postID2(data)
          .then((resp) => {
            //console.log("update aula", resp);
            this.midialessonpreview = null;
            this.mediaTypeLesson = "text";
            this.midialessontitle = "";
            this.midiaaditionalurl = "";
            this.midialesson = "";
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            //console.log(err);
          });
      } else {
        this.midialessonpreview = null;
        this.mediaTypeLesson = "text";
        this.midialesson = "";
      }
    },

    async removeCustomThumb() {
      if (this.editLesson === true) {
        const path = `/${this.cursoAtual}/module/${this.moduleAtual}/lesson/${this.idEditLesson}/metas/custom_thumb`;
        this.loading = true;

        try {
          await serviceCourse.destroy(path);
        } catch (erro) {
          //
        }

        this.midialessonthumbid = null;
        this.midialessonthumb = null;
        this.loading = false;
      }
    },
    addTag(newTag) {
      this.optionsLessonCategory.push(newTag);
    },
    getLessonCategory() {
      serviceCourse
        .read(
          this.cursoAtual + "/module/" + this.moduleAtual + "/small-category"
        )
        .then((resp) => {
          //console.log("get get lesson categoria", resp);
          var array = [];
          resp.filter(function (item) {
            array.push(item.small_category);
          });
          this.optionsLessonCategory = array;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + "/module").then((resp) => {
        //console.log("get modules", resp);
        if (resp.length === 0) {
          notify("erro", "Nenhum Módulo Configurado!");
          return false;
        }
        let routeData = this.$router.resolve("/curso/" + this.cursoAtual);
        window.open(routeData.href, "_blank");
      });
    },
    viewLesson(lessonId) {
      let routeData = this.$router.resolve(
        "/curso/" +
          this.cursoAtual +
          "/modulo/" +
          this.moduleAtual +
          "/aula/" +
          lessonId
      );
      window.open(routeData.href, "_blank");
    },
    onEnd: function (e) {
      //console.log("event", e);

      var array = [];
      var lessons = document.querySelectorAll(".viewAulas");
      for (let i = 0; i < lessons.length; i++) {
        const lessonId = lessons[i].getAttribute("data-id");
        array.push(parseInt(lessonId));
      }
      let data = {
        id: this.cursoAtual + "/module/" + this.moduleAtual + "/lesson/order",
        array,
      };
      this.loading = true;
      serviceCourse
        .postIDArray(data)
        .then((resp) => {
          //console.log("order aula", resp, array);
          this.getLesson();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    async saveAtt() {
      if (this.idEditLesson === "" || this.idEditLesson === null) {
        await this.createAulaToAtt();
      } else {
        let data = {
          id: {
            id:
              this.cursoAtual +
              "/module/" +
              this.moduleAtual +
              "/lesson/" +
              this.idEditLesson +
              "/attachment",
          },
          data: {
            media_id: this.midiaaditional,
          },
        };

        this.loading = true;
        serviceCourse
          .postID2(data)
          .then((resp) => {
            this.midiaaditional = "";
            this.midiaaditionalurl = "";
            this.getAttatchments(this.idEditLesson);
            notify("sucesso", "Arquivo Adicional salvo com Sucesso!");
            this.loading = false;
          })
          .catch((err) => {
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
              if (
                msg ===
                "O valor informado para o campo id da mídia já está em uso.<br>"
              ) {
                this.getAttatchments(this.idEditLesson);
                this.midiaaditional = "";
                this.midiaaditionalurl = "";
              }
            }
            if (msg !== "") {
              notify("erro", msg);
            }
            this.loading = false;
          });
      }
    },
    createAulaToAtt() {
      if (this.idEditLesson === "" || this.idEditLesson === null) {
        var baseUrl = "";
        if (this.mediaTypeLesson === "youtube") {
          baseUrl = "https://www.youtube.com/watch?v=";
        } else if (this.mediaTypeLesson === "vimeo") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "iframe") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "panda") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "audio") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "voomptube") {
          baseUrl = "";
        } else {
          baseUrl = "";
          this.mediaTypeLesson = "text";
        }

        var thumbVideo = "";
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
        }

        var dataSource = baseUrl + this.midialesson;
        if (
          this.midialesson === "" ||
          this.midialesson === null ||
          this.midialesson === undefined
        ) {
          dataSource = "";
        }

        let data = {
          id: {
            id: this.cursoAtual + "/module/" + this.moduleAtual + "/lesson",
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            duration: this.vimeoduration,
            mediaType: this.mediaTypeLesson,
            source: dataSource,
            content: this.descricaoAula,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            small_category: this.categoryLesson,
            status: "draft",
          },
        };
        if (data.data.title === "") {
          data.data.title = this.nameAula;
          return;
        }

        if (this.mediaTypeLesson && this.mediaTypeLesson === "panda") {
          data.data.media_id = this.midiaaditional;
        }

        this.loading = true;
        serviceCourse
          .postID2(data)
          .then((resp) => {
            this.idEditLesson = resp.id;
            if (this.mediaTypeLesson === "audio") {
              var audios = this.AllAudios;
              for (let i = 0; i < audios.length; i++) {
                const audio = audios[i];

                let dataAudio = {
                  id: {
                    id:
                      this.cursoAtual +
                      "/module/" +
                      this.moduleAtual +
                      "/lesson/" +
                      this.idEditLesson +
                      "/audio",
                  },
                  data: {
                    media_id: audio.id,
                  },
                };
                serviceCourse
                  .postID2(dataAudio);
              }
            }
            this.getModules();
            this.editLesson = true;
            this.saveAtt();
            this.loading = false;
          })
          .catch((err) => {
            var error = JSON.parse(err.response.data);
            var msg = "";
            for (var indice in error) {
              msg += error[indice][0] + "<br>";
            }
            this.openCreateAula();
            this.loading = false;
          });
      } else {
        this.getModules();
        this.editLesson = true;
        this.saveAtt();
      }
    },
    removeAudio(audioId) {
      this.AllAudios.find((item, index) => {
        if (item.id === audioId) {
          this.AllAudios.splice(index, 1);
          return item;
        }
      });

      this.loading = true;
      serviceCourse
        .destroy(
          this.cursoAtual +
            "/module/" +
            this.moduleAtual +
            "/lesson/" +
            this.idEditLesson +
            "/audio/" +
            audioId
        )
        .then((resp) => {
          //console.log("delete audio", resp);
          this.getAudios(this.idEditLesson);
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    removeAttr(attachId) {
      this.loading = true;
      serviceCourse
        .destroy(
          this.cursoAtual +
            "/module/" +
            this.moduleAtual +
            "/lesson/" +
            this.idEditLesson +
            "/attachment/" +
            attachId
        )
        .then((resp) => {
          //console.log("delete attatchment", resp);
          this.getAttatchments(this.idEditLesson);
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getAudios(lesson) {
      if (lesson.id === undefined) {
      } else {
        this.nameLesson = lesson.title;
      }
      serviceCourse
        .read(
          this.cursoAtual +
            "/module/" +
            this.moduleAtual +
            "/lesson/" +
            this.idEditLesson +
            "/audio"
        )
        .then((resp) => {
          //console.log("get audios", resp);
          this.AllAudios = resp;
          this.loading = false;
          if (resp[0]) {
            this.midialessontitle = resp[0].title
            this.midialessonpreview = resp[0].thumb
            this.mediaTypeLesson = "audio"
          }
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getAttatchments(lesson) {
      //console.log('getAttatchments', this.idEditLesson);
      this.loading = true;
      serviceCourse
        .read(
          this.cursoAtual +
            "/module/" +
            this.moduleAtual +
            "/lesson/" +
            this.idEditLesson +
            "/attachment"
        )
        .then((resp) => {
          //console.log("get attatchments", resp);
          this.AllAttachments = resp;
          this.quantidadeAtt = resp.length;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    enventSwitchStatus() {
      var xxxStatus = "";
      if (this.myDataVariable === true) {
        xxxStatus = "published";
      } else {
        xxxStatus = "draft";
      }
      let data = {
        id: this.cursoAtual,
        status: xxxStatus,
      };
      //console.log(data);
      this.loading = true;
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then((resp) => {
          //console.log("update course status", resp);
          this.getCourse();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    notSaveLesson() {
      this.nameAula = "";
      this.descricaoAula = "";
      this.descricaoAula = "";
      this.addAula = false;
      this.editLesson = false;
      this.idEditLesson = "";
      this.midialessonthumbid = null;
      this.midialessonthumb = null;
      this.typeContentSelected = null;
      this.quiz = {};
      this.questions = {};
      this.getLesson();
      if (this.$route.query.lesson_id) {
        this.$router.push(
          "/config_curso/" +
            this.$route.params.curso +
            "/module/" +
            this.$route.params.module +
            "/lesson"
        );
      }
    },
    saveEditLesson(xStatus) {
      let data = {};
      if (this.typeContentSelected === "QUIZ") {
        data = {
          id: {
            id:
              this.cursoAtual +
              "/module/" +
              this.moduleAtual +
              "/lesson/" +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            status: xStatus,
            mediaType: "quiz",
            source: "quiz",
            content: this.descricaoAula,
            small_category: this.categoryLesson,
          },
        };
      } else {
        var baseUrl = "";
        if (this.mediaTypeLesson === "youtube") {
          baseUrl = "https://www.youtube.com/watch?v=";
        } else if (this.mediaTypeLesson === "voomptube") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "vimeo") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "iframe") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "panda") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "audio") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "voomptube") {
          baseUrl = "";
        } else {
          baseUrl = "";
          this.mediaTypeLesson = "text";
        }
        var thumbVideo = "";
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
          // }else{
          //     thumbVideo = this.midialessonthumb.url
        }

        var dataSource = baseUrl + this.midialesson;
        if (
          this.midialesson === "" ||
          this.midialesson === null ||
          this.midialesson === undefined
        ) {
          dataSource = "";
        }

        var duracaoUpdate = this.durationToEdit;
        if (
          this.durationToEdit === null ||
          (this.durationToEdit === "" && this.mediaTypeLesson === "vimeo")
        ) {
          duracaoUpdate = this.vimeoduration;
        }

        data = {
          id: {
            id:
              this.cursoAtual +
              "/module/" +
              this.moduleAtual +
              "/lesson/" +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            mediaType: this.mediaTypeLesson,
            source: dataSource,
            content: this.descricaoAula,
            duration: duracaoUpdate,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            status: xStatus,
            small_category: this.categoryLesson,
          },
        };

        if (this.mediaTypeLesson === "panda") {
          data.data.media_id = this.mediaPandaId;
          data.data.source = "";
        }
      }

      this.loading = true;
      serviceCourse
        .postID2(data)
        .then((resp) => {
          //console.log("update aula", resp);
          this.getLesson();
          notify("sucesso", "Aula Salva com Sucesso!");
          if (this.mediaTypeLesson === "audio") {
            var audios = this.AllAudios;
            for (let i = 0; i < audios.length; i++) {
              const audio = audios[i];

              let dataAudio = {
                id: {
                  id:
                    this.cursoAtual +
                    "/module/" +
                    this.moduleAtual +
                    "/lesson/" +
                    this.idEditLesson +
                    "/audio",
                },
                data: {
                  media_id: audio.id,
                },
              };
              serviceCourse
                .postID2(dataAudio)
                .then((resp) => {
                  //console.log(resp);
                })
                .catch((err) => {
                  //console.log(err);
                });
            }
          }

          this.nameAula = "";
          this.descricaoAula = "";
          this.addAula = false;
          this.editLesson = false;
          this.midialessonthumbid = null;
          this.midialessonthumb = null;
          this.quiz = {};
          this.getModules();
          if (this.$route.query.lesson_id) {
            this.$router.push(
              "/config_curso/" +
                this.$route.params.curso +
                "/module/" +
                this.$route.params.module +
                "/lesson"
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          this.loading = false;
        });
    },
    deleteLesson(idLesson) {
      let data = {
        id:
          this.cursoAtual +
          "/module/" +
          this.moduleAtual +
          "/lesson/" +
          idLesson,
      };
      this.loading = true;
      serviceCourse
        .destroy(data)
        .then((resp) => {
          //console.log("delete aula", resp);
          notify("sucesso", "Aula excluída com Sucesso!");
          this.getLesson();
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao deletar Aula com Sucesso!");
          this.loading = false;
        });
    },
    addedQuestion(data) {
      this.quiz = data;
      if (this.editLesson) {
        this.saveQuestion(this.idEditLesson, this.$route.params.module);
      }
    },
    createAula(idModule, xStatus) {
      let data = {};

      if (this.typeContentSelected === "QUIZ") {
        data = {
          id: {
            id: this.cursoAtual + "/module/" + idModule + "/lesson",
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            status: xStatus,
            mediaType: "quiz",
            source: "quiz",
            content: this.descricaoAula,
            small_category: this.categoryLesson,
          },
        };
      } else {
        //console.log(this.AllAulas);
        var baseUrl = "";
        if (this.mediaTypeLesson === "youtube") {
          baseUrl = "https://www.youtube.com/watch?v=";
        } else if (this.mediaTypeLesson === "vimeo") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "iframe") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "panda") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "audio") {
          baseUrl = "";
        } else if (this.mediaTypeLesson === "voomptube") {
          baseUrl = "";
        } else {
          baseUrl = "";
          this.mediaTypeLesson = "text";
        }

        var thumbVideo = "";
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
          // }else{
          //     thumbVideo = this.midialessonthumb.url
        }

        if (
          this.midialesson === "" ||
          this.midialesson === "https://www.youtube.com/watch?v="
        ) {
          this.mediaTypeLesson = "text";
        }

        data = {
          id: {
            id: this.cursoAtual + "/module/" + idModule + "/lesson",
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            duration: this.vimeoduration,
            mediaType: this.mediaTypeLesson,
            source: baseUrl + this.midialesson,
            content: this.descricaoAula,
            order: this.AllAulas.length,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            status: xStatus,
            small_category: this.categoryLesson,
          },
        };

        if (this.mediaTypeLesson === "panda") {
          data.data.media_id = this.mediaPandaId;
          data.data.source = "";
        }
        if (this.mediaTypeLesson === "audio") {
          data.data.mediaType = "audio";
        }
      }
      this.loading = true;
      serviceCourse
        .postID2(data)
        .then(async (resp) => {
          //console.log("created aula", resp);
          this.module_id = "";

          if (this.typeContentSelected === "QUIZ") {
            await this.saveQuestion(resp.id, idModule);
          } else {
            setTimeout(() => {
              this.onEnd();
            }, 1000);
            if (this.mediaTypeLesson === "audio") {
              var audios = this.AllAudios;
              for (let i = 0; i < audios.length; i++) {
                const audio = audios[i];

                let dataAudio = {
                  id: {
                    id:
                      this.cursoAtual +
                      "/module/" +
                      idModule +
                      "/lesson/" +
                      resp.id +
                      "/audio",
                  },
                  data: {
                    media_id: audio.id,
                  },
                };
                serviceCourse
                  .postID2(dataAudio)
                  .then((resp) => {
                    //console.log(resp);
                  })
                  .catch((err) => {
                    //console.log(err);
                  });
              }
            }
            this.getModules();
            this.addAula = false;
            notify("sucesso", "Aula Criada com Sucesso!");
            this.loading = false;
          }
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
          this.loading = false;
        });
    },
    async saveQuestion(lessonId, idModule) {
      let index = 0;
      this.loading = true;

      for await (let question of this.quiz.questions) {
        if (!question.text) {
          continue;
        }

        let data = {
          id: {
            id: `${
              this.cursoAtual
            }/module/${idModule}/lesson/${lessonId}/question/${
              question.id ?? ""
            }`,
          },
          data: {
            text: question.text,
          },
        };

        let options = question.answers;
        await serviceCourse
          .postID2(data)
          .then(async (respQuestion) => {
            for await (let option of options) {
              let dataOption = {
                id: {
                  id: `${
                    this.cursoAtual
                  }/module/${idModule}/lesson/${lessonId}/question/${
                    respQuestion.id
                  }/answer/${option.id ?? ""}`,
                },
                data: {
                  text: option.text,
                  is_correct: option.is_correct,
                },
              };
              await serviceCourse
                .postID2(dataOption)
                .then((respOption) => {})
                .catch((e) => {
                  console.error(e.message);
                  notify("erro", "Falha ao adicionar a Alternativa!");
                });
            }
            delete this.quiz.questions[index];
            index++;
          })
          .catch((e) => {
            this.loading = false;

            console.error(e.message);
            notify("erro", "Falha ao adicionar a Questão!");
            return false;
          });
      }
      if (this.editLesson) {
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse
          .get(cursoId + "/module/" + moduleId + "/lesson/" + lessonId)
          .then((resp) => {
            this.questions = resp.questions;
          });

        notify("sucesso", "Questão editada com Sucesso!");
        this.loading = false;

        return true;
      }
      setTimeout(() => {
        this.onEnd();
      }, 1000);
      this.addAula = false;
      notify("sucesso", "Aula Criada com Sucesso!");
      this.loading = false;
    },
    async closeModalOptions(lessonId) {
      if (this.editLesson) {
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse
          .get(cursoId + "/module/" + moduleId + "/lesson/" + lessonId)
          .then((resp) => {
            this.questions = resp.questions;
          });
      }
    },
    removedQuestion(data) {
      this.questions = data;
    },
    openCreateAula() {
      this.nameAula = "";
      this.descricaoAula = "";
      this.midialesson = "";
      this.midialessonpreview = null;
      this.addAula = true;
      this.midiaaditional = "";
      this.midiaaditionalurl = "";
      this.quantidadeAtt = 0;
      this.AllAttachments = "";
      this.midialessonthumbid = null;
      this.midialessonthumb = null;
      this.categoryLesson = null;
      this.AllAudios = [];
      this.idEditLesson = null;

      // serviceMember
      // .read('/meta?keys[]=onboarding')
      // .then((resp) => {
      // //console.log("meta onboarding", resp);
      // var data = resp.onboarding;
      //     if(data === null){
      //         this.nameAula = "Aula 1"
      //         this.descricaoAula = "<p>Descrição Aula 1</p>"
      //         this.mediaTypeLesson = "youtube"
      //         this.midialesson = "DpIjpxr7jZk";
      //         this.midialessonpreview = "https://i.ytimg.com/vi/DpIjpxr7jZk/default.jpg";
      //     }
      // })
    },
    editLessonFuntion(dataLesson) {
      this.AllAudios = dataLesson.audios;
      this.nameAula = dataLesson.title;
      this.descricaoAula = dataLesson.description;
      this.midialesson = dataLesson.source;
      this.addAula = true;
      if (dataLesson.source) {
        this.midialessonpreview = dataLesson.thumb;
      }
      if (dataLesson.custom_thumb === null) {
        this.midialessonthumbid = null;
        this.midialessonthumb = null;
      } else {
        this.midialessonthumbid = dataLesson.custom_thumb.id;
        let data = {
          url: dataLesson.custom_thumb.cdn_url,
          id: dataLesson.custom_thumb.id,
        };
        this.midialessonthumb = data;
      }
      this.mediaTypeLesson = dataLesson.mediaType;
      this.midiaaditional = "";
      this.midiaaditionalurl = "";
      this.editLesson = true;
      this.idEditLesson = dataLesson.id;
      this.AllAttachments = dataLesson.attachments;
      this.quantidadeAtt = dataLesson.attachments.length;
      this.durationToEdit = dataLesson.duration;
      this.descricaoAula = dataLesson.content;
      this.categoryLesson = dataLesson.small_category;
      this.questions = {};
      this.quiz = {};
      if (dataLesson.mediaType === "quiz") {
        this.typeContentSelected = "QUIZ";
        this.questions = dataLesson.questions;
      } else if (dataLesson.mediaType === "audio") {
        this.typeContentSelected = "AUDIO";
      } else if (dataLesson.mediaType === "panda") {
        this.mediaPandaId = dataLesson.media.id;
      } else if (dataLesson.mediaType === "text") {
        this.typeContentSelected = "TEXT";
      } else {
        this.typeContentSelected = "VIDEO";
      }
      this.AllAudios = dataLesson.audios;
      this.getAudios(this.idEditLesson);
    },
    deleteModule(id) {
      if (this.totalModules !== 1) {
        this.loading = true;
        serviceCourse
          .destroy(this.cursoAtual + "/module/" + id)
          .then((resp) => {
            //console.log("delete module", resp);
            this.getModules();
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
    showModalUploadThumb() {
      this.$root.$emit("uploadlessonthumb");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUpload() {
      this.$root.$emit("uploadlesson", this.typeContentSelected.toLowerCase());
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadAditional() {
      if (this.nameAula === "" || this.nameAula === null) {
        notify("erro", "Necessario inserir o nome da aula!");
        return;
      }
      this.$root.$emit("uploadlessonadditional");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadItem() {
      let data = {
        type: this.mediaTypeLesson,
        link: this.midialesson,
      };
      this.$root.$emit("uploadlessonitem", data);
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    hideModalUpload() {
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    getModules() {
      this.loading = true;
      serviceCourse
        .read(this.cursoAtual + "/module/" + this.moduleAtual)
        .then((resp) => {
          //console.log("get module", resp);
          this.ModuleLessons = resp;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getCourse() {
      this.loading = true;
      let data = {
        id: this.cursoAtual,
      };
      serviceCourse
        .read(data)
        .then((resp) => {
          //console.log("get course", resp);
          this.nameCourse = resp.title;
          this.loading = false;
        })
        .catch((err) => {
          //console.log(err);
          this.loading = false;
        });
    },
    getLesson() {
      const params = this.$route.params.curso;
      const params2 = this.$route.params.module;
      //console.log(params, params2, this.$route);
      let tath = this;
      if (
        params === null ||
        params === undefined ||
        params === "" ||
        params2 === null ||
        params2 === undefined ||
        params2 === ""
      ) {
        this.$router.push("/course");
      } else {
        this.loading = true;
        serviceCourse
          .read(tath.cursoAtual + "/module/" + params2 + "/lesson")
          .then((resp) => {
            //console.log("get lesson", resp);
            this.AllAulas = resp;
            var aulas = this.AllAulas;
            this.getCourse();
            if (this.$route.query.lesson_id) {
              for (let i = 0; i < aulas.length; i++) {
                const element = aulas[i];
                if (element.id === parseInt(this.$route.query.lesson_id, 10)) {
                  this.editLessonFuntion(element);
                }
              }
            }
            this.loading = false;
          })
          .catch((err) => {
            //console.log(err);
            this.loading = false;
          });
      }
    },
    onEditorChange(value) {
      this.descricaoAula = value
    }
  },
  mounted() {
    this.cursoAtual = this.$route.params.curso;
    this.moduleAtual = this.$route.params.module;
    this.getModules();
    this.getLesson();
    this.getLessonCategory();
    this.$root.$on("midiaaulas", (data) => {
      this.midiaaulas = parseInt(data);
    });
    this.$root.$on("uploadlessonnew", (data) => {
      //console.log("data video", data);
      this.AllAudios.push(data);
      this.midialesson = data.id;
      this.midialessonpreview = data.url;
      this.midialessontitle = data.title;
      this.mediaTypeLesson = data.type;
      if (data.type === "audio/mpeg") {
        this.mediaTypeLesson = "audio";
      }
    });
    this.$root.$on("midialessonyt", (data) => {
      //console.log("data video yt link", data);
      this.mediaTypeLesson = "youtube";
      this.midialesson = data.id.videoId;
      this.midialessonpreview = data.snippet.thumbnails.standard
        ? data.snippet.thumbnails.standard.url
        : data.snippet.thumbnails.default.url;
    });
    this.$root.$on("midialessonpanda", (data) => {
      //console.log("data video", data);
      this.mediaTypeLesson = "panda";
      this.mediaPandaId = data.id;
      this.midialesson = data.cdn_url;
      this.midialessonpreview = data.cdn_url;
    });
    this.$root.$on("midialessonvimeo", (data) => {
      //console.log("data video vimeo", data);
      this.mediaTypeLesson = "vimeo";
      this.midialesson = data.link;
      this.vimeoduration = data.duration;
      if (data.pictures.sizes[0].link === "vimeolink") {
        this.midialessonpreview = `https://app.voompplay.${process.env.VUE_URL_DOMAIN}/vimeo.jpg`;
      } else {
        this.midialessonpreview = data.pictures.sizes[0].link;
      }
    });
    this.$root.$on("midialessoniframe", (data) => {
      //console.log("data video iframe", data);
      this.mediaTypeLesson = "iframe";
      this.midialesson = data.link;
      this.vimeoduration = 0;
      this.midialessonpreview = `https://app.voompplay.${process.env.VUE_URL_DOMAIN}/iframe.png`;
    });
    this.$root.$on("midiaadditional", (data) => {
      this.midiaaditional = parseInt(data.id);
      this.midiaaditionalurl = data.url;
      this.midiaaditionalurltype = data.type;
      this.saveAtt();
    });
    this.$root.$on("uploadlessonthumbnew", (data) => {
      this.midialessonthumbid = parseInt(data.id);
      this.midialessonthumb = data;
    });
    this.$root.$on("stepconcluirinitaula", (data) => {
      this.openCreateAula();
    });
    this.$root.$on("stepconcluiraula", (data) => {
      this.createAula(this.moduleAtual, "published");
    });
  },
  beforeDestroy() {
    this.$root.$off("midiaadditional");
  },
};
</script>
<style lang="scss">
.createLessons {
  .removeLessonVideo, .removeCustomThumb {
    margin-left: 5px;
    opacity: 1;
    transition: 0.3s;
    svg {
      filter: var(--filtericon);
    }
  }

  .removeCustomThumb{
    cursor: pointer !important;
  }

  .textSubcategory {
    font-size: 14px;
    font-weight: 600;
    color: var(--maincolor);
    margin-bottom: 3px;
  }
  .marginSubCategory {
    margin-left: 15px;
  }
  .spaceInputs2 {
    margin-bottom: 30px;
    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
    .multiselect__tags {
      height: 55px;
      border-radius: 30px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
      padding: 15px;
    }
    .multiselect__select:before {
      top: 95% !important;
    }
  }
  .lineDivAtt {
    height: 0.5px;
    background: #ededf0;
    margin-bottom: 20px;
    width: 100%;
  }
  .spaceAttList {
    margin-bottom: 2px;
    margin-top: 15px;
  }
  .totalAtt {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2);
  }
  .removeLink {
    text-decoration: none;
  }
  .btn-openModalPreview {
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 20px;
    min-height: 165px;

    .removeAttr {
      position: absolute;
      top: 15%;
      right: 5%;
      opacity: 1;
      transition: 0.3s;
      svg {
        filter: var(--filtericon);
      }
    }

    img {
      border-radius: 3px;
    }

    iframe {
      width: fit-content;
      height: unset;
      border-radius: 3px;
    }

    .imagePreviewCapa {
      width: 100px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
    }
  }
  .btn-openModalPreview:hover {
    .removeAttr {
      opacity: 1;
      transition: 0.3s;
    }
  }

  .cursor-auto {
    cursor: auto !important;
  }

  .navigate {
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(0, 35, 99, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        border: none;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #002363;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Inter;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 30px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 5px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .textAula2 {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg, .isax-edit {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
          top: -14px;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 30px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 30px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
          line-height: 3em;
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Inter !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: var(--backgroundcolor);
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 32px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .spaceInputs {
        select {
          background-color: var(--backgroundcolor) !important;
        }
        margin-bottom: 30px;
        .flexBtn {
          display: flex;
          gap: 35px;
        }
        .textInfo {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-att-add {
          background: rgba(0, 35, 99, 0.1);
          border: none;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #002363;
          width: 100%;
          height: 55px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .btn-cancela {
          background: #f7f7f7;
          border: 1px solid#f7f7f7;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #81858e;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-rascunho {
          background: rgba(0, 35, 99, 0.1);
          border: none;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 30px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #002363;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 25em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 30px;
          height: 55px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor2);
        }
      }
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: initial;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .containerx {
    width: 98%;
  }
}

.imgAudio {
  height: 80px;
  margin: 0 auto;
}
</style>
